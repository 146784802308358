import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={1} title={"Hackaviz 2018"} description={"Les données"} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`Les communes d’Occitanie`}</h1>
        <p>{`Le fichier que l’association TOULOUSE DATAVIZ a préparé pour l’HACKAVIZ_2018  regroupe un ensemble varié de données sur les communes d’Occitanie.  Ces données ont été extraites à partir de fichiers mis à disposition sur le site data gouv .`}</p>
        <p>{`Ces données proviennent de l’IGN, l’INSEE, du ministère de l’intérieur et d’Open Street Map :`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://public.opendatasoft.com/explore/dataset/sirene/export/?flg=fr&dataChart=eyJxdWVyaWVzIjpbeyJjaGFydHMiOlt7InR5cGUiOiJjb2x1bW4iLCJmdW5jIjoiQ09VTlQiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMUI2Njk4In1dLCJ4QXhpcyI6ImxpYnRlZmVuIiwibWF4cG9pbnRzIjoiIiwidGltZXNjYWxlIjoiIiwic29ydCI6InNlcmllMS0xIiwiY29uZmlnIjp7ImRhdGFzZXQiOiJzaXJlbmUiLCJvcHRpb25zIjp7ImZsZyI6ImZyIn19fV0sInRpbWVzY2FsZSI6IiJ9"
            }}>{`Base SIRENE`}</a>{` base des entreprises`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://professionnels.ign.fr/geofla"
            }}>{`Caractéristiques des communes`}</a></li>
          <li parentName="ul">{`Population (BTX_TD_POP1B_2014.csv)`}</li>
          <li parentName="ul">{`Population_poplegale_6814.xls`}</li>
          <li parentName="ul">{`Fichier Localisé Social et Fiscal (Fichier Localisé Social et Fiscal (FiLoSoFi) – Année 2014)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.data.gouv.fr/fr/datasets/election-presidentielle-des-23-avril-et-7-mai-2017-resultats-definitifs-du-2nd-tour-par-communes/"
            }}>{`Résultats des élections présidentielles 2017 par commune`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.data.gouv.fr/fr/datasets/kilometrage-des-types-de-routes-repartis-par-communes/"
            }}>{`Kilométrage de routes par commune et par type`}</a></li>
        </ul>
        <Button link={"https://github.com/ToulouseDataViz/Hackaviz2018/blob/master/TDV_hackaviz_2018.xlsx"} text={"xls"} mdxType="Button" />
        <Button link={"https://github.com/ToulouseDataViz/Hackaviz2018/blob/master/TDV_hackaviz_2018.csv"} text={"csv"} mdxType="Button" />
        <Button link={"https://github.com/ToulouseDataViz/Hackaviz2018/blob/master/TDV_hackaviz_2018_geojson.zip"} text={"geojson"} mdxType="Button" />
        <p>{`Les fichiers csv et xlsx  contiennent 4516 lignes (une ligne par commune) + une ligne d’entête et 95 colonnes.`}</p>
        <p>{`Le fichier json contient les mêmes données sous un format geojson. Ce format est adapté pour ceux qui souhaitent utiliser des outils de cartographie tels que le logiciel libre `}<a parentName="p" {...{
            "href": "https://www.qgis.org/fr/site/forusers/download.html"
          }}>{`QGIS`}</a>{` ou des librairies javascript telles que `}<a parentName="p" {...{
            "href": "https://d3js.org/"
          }}>{`d3.js`}</a></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`CATEGORIE`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`NOM DES COLONNES`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`FORMAT`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`UNITE`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`code unique pour chaque commune (1)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`CODGEO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`nom de la commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`numéro du département`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`code_dept`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nom du département`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nom_dept`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Latitude de la commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`lat-commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Degré`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Longitude de al commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`long-commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Degré`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Altitude moyenne de la commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`z_moyen`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`mètre`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Statut de la commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`statut`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Superficie de la commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`superficie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hectare`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Contour de la commune (lat –long)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`geometry_commune`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Degré`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombres d’équipements`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Hypermarché`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Supermarché`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Grande surface de bricolage`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Supérette`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Epicerie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Boulangerie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Boucherie charcuterie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Produits surgelés`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Poissonnerie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Librairie papeterie journaux`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin de vêtements`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin d’équipements du foyer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin de chaussures`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin d’électroménager et de matériel audio-vidéo`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin de meubles`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin d’articles de sports et de loisirs`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin de revêtements murs et sols`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Droguerie quincaillerie bricolage`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Parfumerie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Horlogerie Bijouterie`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fleuriste`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin d’optique`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Magasin de matériel médical et orthopédique`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Station-service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Distribution des revenus disponibles par unité de consommation et composition du revenu disponible (2)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre_ménages_fiscaux`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre_personnes_dans_ménages_fiscaux`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Nbre_unités_de_consommation_dans_ménages_fiscaux`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`médiane_du_revenu_disponible_par_unité_de_consommation_euro`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Euro`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Résultats de l’élection présidentielle 2017 1er Tour :  candidat arrivé en tête`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`P_2017_1T_cand_1er_Nom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`String`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`P`}<em parentName="td">{`2017_1T_cand_1er`}</em>{`% Voix/Exp`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`%`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Résultats de l’élection présidentielle 2017  2ième Tour`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`P`}<em parentName="td">{`2017_2T`}</em>{`% Voix/Exp E Macron`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`%`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`P`}<em parentName="td">{`2017_2T`}</em>{`% Voix/Exp M LePen`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`%`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Kilométrage de route par commune et par type (définition OSM)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`residential /”accès zone résidentielle”`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`secondary / “route départementale”`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`service /route d’accès à un parking, batiment,zone d’activité,station service,plage, camping..`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`tertiary / “route intercommunales”`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`unclassified / “route communale”`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`primary / “route nationale”`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`motorway /autoroute`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`trunk / voie rapide 2×2 voies + séparation centrale`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Km`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SIRENE  DCRET  Nombre annuel de créations d’entreprises (établissement) dans le secteur  de l’informatique ( NAF 62 : Programmation conseil et autres activités  informatiques et NAF 63 : Services d’information)Attention : ce nombre  de créations correspond au nombre d’entreprises créées dans l’année qui sont toujours en activité en 2017. Les entreprises qui ont arrêté leurs activités ne sont pas tracées dans la base.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1982`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1983`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1984`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1985`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1986`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1987`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1988`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1989`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1990`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1991`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1992`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1993`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1994`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1995`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1996`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1997`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1998`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1999`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2000`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2001`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2002`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2003`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2004`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2005`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2006`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2007`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2008`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2009`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2010`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2011`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2012`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2013`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2015`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2016`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2017`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’hommes dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Total population hommes_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’hommes dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Total_population_femmes_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Moyenne de l’âge des hommes dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`moyenne_age_H_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Moyenne de l’âge des femmes dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`moyenne_age_F_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Moyenne de l’âge des habitants dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`moyenne_HF_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Real`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’habitants dans la commune en 2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`population_2014`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’habitants dans la commune en 1999`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`population_1999`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’habitants dans la commune en 1982`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`population_1982`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Nombre total d’habitants dans la commune en 1968`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`population_1968`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <p>{`(1) En raison du regroupement de certaines communes, leur nombre diminue avec le temps. Lorsque plusieurs communes se regroupent, seul un des codes uniques est retenu et les autres codes disparaissent. Nous avons retenu le statut actuel des communes et fait les jointures des fichiers sur cette base. Cela concerne environ une cinquantaine de petites communes rurales. En conséquence :`}</p>
        <ul>
          <li parentName="ul">{`Les données des communes dont le code unique a disparu n’apparaissent pas dans le fichier`}</li>
          <li parentName="ul">{`A partir de la date de regroupement et pour la commune dont le code unique a été maintenu, les données évoluent fortement (par exemple pour la population).`}</li>
          <li parentName="ul">{`plus d’info`}</li>
        </ul>
        <p>{`(2) Dans chaque ménage fiscal, on compte le nombre d’équivalents adultes qui le composent :`}</p>
        <ul>
          <li parentName="ul">{`le premier adulte compte pour 1`}</li>
          <li parentName="ul">{`les autres personnes de 14 ans et plus pour 0,5`}</li>
          <li parentName="ul">{`les enfants de moins de 14 ans pour 0,3.`}</li>
        </ul>
        <HackavizFooter year={'2018'} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      